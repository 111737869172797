<app-layout [defaultOptions]="{noNav: true}">
  <div class="card-title text-center">
      <h3>4GoodRewards Membership Purchase</h3>
  </div>
  <div class="container">
    <div class="card profile-card">
      <div class="card-body">
        <div class="row">
          <div class="app-spinner initial" *ngIf="!inviteStr">
            <div class="fa-5x">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </div>
          <div *ngIf="inviteStr">
            {{inviteStr}}
          </div>

          <div *ngIf="fundraiserNotFound">
            Fundraiser not found.
          </div>
          <div *ngIf="inviteNotFound">
            Invalid invite code.
          </div>
          <div *ngIf="membershipActive">
            Congratulations - you're membership is now active!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="productFundraiser" class="row m-3 p-2" class="stepper-container">
      <mat-horizontal-stepper labelPosition="bottom" linear id="stepper">
          <mat-step label="Product Information" [completed]="false" [editable]="editable">
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-sm-12">
                    <img [src]="productFundraiser.imageUrl" [alt]="productFundraiser.name">
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    {{productFundraiser.shortDescription}}
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-sm-12">
                    <h4>{{productFundraiser.name}}</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <span class="bold">Fundraiser End Date:</span> {{getEndDate()}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mt-1">
                    {{productFundraiser.description}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row price-row">
              <div class="col-sm-12">
                <span class="product-price">${{productFundraiser.price?.toFixed(2)}}</span>
                <span class="earn-amount ml-2">Earns ${{productFundraiser.earnAmount?.toFixed(2)}}</span>
              </div>
            </div>
            <div class="row button-row">
              <div class="col-md-12">
                <button class="btn btn-default pt-2 pb-2 mt-3 mr-3" (click)="donateOnlyNext()">Donate Only</button>
                <button class="btn btn-primary pt-2 pb-2 mt-3" (click)="purchaseNext()">Purchase</button>
              </div>
            </div>
          </mat-step>

          <mat-step label="Payment" [completed]="false" [editable]="editable">
            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <div class="row">
                  <div class="col-12">
                    <label>Credit Card:</label>
                    <input autocomplete="cc-number" type="text" id="cc-number" name="cc-number" class="form-control"
                          [(ngModel)]="creditCard.number" required autofocus>
                    <div class="text-danger" *ngIf="ccErrorMessage">
                      {{ccErrorMessage}}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>Expiration Month:</label>
                    <input autocomplete="cc-exp-month" type="number" id="cc-exp-month" name="cc-exp-month" class="form-control"
                          [(ngModel)]="creditCard.expiration.month" required min="1" max="12">
                    <div class="text-danger" *ngIf="expirationErrorMessage">
                      {{expirationErrorMessage}}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Expiration Year:</label>
                    <input autocomplete="cc-exp-year" type="number" id="cc-exp-year" name="cc-exp-year" class="form-control"
                          [(ngModel)]="creditCard.expiration.year" required>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label>CVV:</label>
                    <input autocomplete="cc-csc" type="text" id="cc-csc" name="cc-csc" class="form-control" style="max-width:10em"
                          [(ngModel)]="creditCard.cvv">
                      <div class="text-danger" *ngIf="cvvErrorMessage">
                        {{cvvErrorMessage}}
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <h4>Purchase Summary</h4>
                  </div>
                </div>
                <div class="row mb-1" *ngIf="!donateOnly">
                  <div class="col-12">
                    A purchase of ${{productFundraiser.price?.toFixed(2)}} will be charged via your credit card.
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label *ngIf="donateOnly">Donation Amount:</label>
                    <label *ngIf="!donateOnly">Additional Donation:</label>
                    <input type="number" id="donation-amount" name="donation-amount" class="form-control"
                          [(ngModel)]="donationAmount" [required]="donateOnly">
                    <div class="text-danger" *ngIf="donationErrorMessage">
                      {{donationErrorMessage}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row button-row">
              <div class="col-md-12">
                <button class="btn btn-default pt-2 pb-2 mt-3 mr-3" (click)="back()">Back</button>
                <button class="btn btn-primary pt-2 pb-2 mt-3" (click)="validateCcStep()">Next</button>
              </div>
            </div>
          </mat-step>
          <mat-step label="Confirm" [completed]="false" [editable]="editable">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <h4>Billing Info</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    Credit Card: {{getMaskedNumber()}}
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-12">
                    Expiration: {{getExpiration()}}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <h4>Purchase Summary</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div *ngIf="!donateOnly" class="flex space-between">
                      <div class="bold">{{productFundraiser.name}}</div>
                      <div>${{productFundraiser.price?.toFixed(2)}}</div>
                    </div>
                    <div *ngIf="donationAmount > 0" class="flex space-between">
                      <div class="bold">Donation</div>
                      <div>${{donationAmount?.toFixed(2)}}</div>
                    </div>
                    <div class="flex space-between">
                      <div class="bold mt-2">Total</div>
                      <div class="mt-auto">${{((donateOnly ? 0 : productFundraiser.price) + donationAmount).toFixed(2)}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row button-row">
              <div class="col-md-12 error text-danger">
                {{errorMessage}}
              </div>
              <div class="col-md-12">
                <button class="btn btn-default pt-2 pb-2 mt-3 mr-3" (click)="back()">Back</button>
                <button class="btn btn-primary pt-2 pb-2 mt-3" (click)="completePurchase()">Complete</button>
              </div>
            </div>
          </mat-step>

          <mat-step label="Complete" [completed]="false" [editable]="editable">
              <div class="row">
                <div class="col-12">
                  <h4 class="flex justify-center">Thank you</h4>
                  <div class="flex justify-center">Thank you for your contribution.</div>
                  <div class="flex justify-center mt-3">
                    <button class="btn btn-primary btn-padding" (click)="done()">
                      Done
                    </button>
                  </div>
                </div>
              </div>
          </mat-step>
      </mat-horizontal-stepper>
  </div>
</app-layout>
