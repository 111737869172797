import { Component, Input, OnInit } from '@angular/core';
import { ILayoutData, LayoutData, LayoutService } from './layout.service';

/**
 * NOT the component to use with routing. Use {@link DefaultLayoutComponent} for that.
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @Input() defaultOptions?: ILayoutData;

  constructor(public layout: LayoutService) {}

  ngOnInit() {
    this.layout.defaultOptions = new LayoutData(this.defaultOptions);
    console.log(this.layout.defaultOptions)
  }
}
