import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthService, BrowserExtensionService, WPImagesService } from '../../../core';
import { LayoutService } from '../layout.service';
import { LayoutMode } from '../models/layout-mode.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public readonly LayoutMode = LayoutMode;
  public readonly mobileApp = environment.mobileApp;
  public readonly year = new Date().getFullYear();

  public readonly url = {
    website: environment.huterra.website.url,
    raiseUp: environment.raiseUp.website.url,
    faq: `https://huterra.uservoice.com/knowledgebase`
  };

  public isAuthenticated = false;

  private destroy$ = new Subject<void>();

  constructor (public images: WPImagesService,
               public layout: LayoutService,
               public browserExt: BrowserExtensionService,
               public authService: AuthService) {
  }

  ngOnInit() {
    this.authService.authChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.isAuthenticated = status.isAuthenticated;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
