import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService, Logger } from '../../../../core';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
  host: {
    class: 'auth-form auth-form-sign-in'
  }
})
export class SignInFormComponent implements OnInit {
  @Output() success = new EventEmitter<void>();

  @ViewChild('loginForm', { static: true }) loginForm: NgForm;
  public alert: { type: string, msg: string } = {
    type: 'success',
    msg: null
  };

  public model: { username: string, password: string } = {
    username: null,
    password: null
  };

  public loading = false;

  constructor(
    private router: Router,
    private logger: Logger,
    private auth: AuthService) { }

  ngOnInit() {}

  alertClose() {
    this.alert = null
  }

  public onSubmit(): void {
    this.loading = true;

    this.auth.login(this.model.username, this.model.password)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        () => {
          this.success.emit();
        },
        err => {
          this.logger.error(err);

          this.alert = {
            type: 'danger',
            msg: 'Username or password was incorrect.'
          };
        }
      );
  }
}
