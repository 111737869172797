import { CommonModule } from '@angular/common'
import { ProductFundraiserComponent } from './product-fundraiser.component'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { AlertModule } from 'ngx-bootstrap/alert'
import { AuthFormModule } from '../auth/auth-form/auth-form.module'
import { MatStepperModule } from '@angular/material/stepper'
import { MatIconModule } from '@angular/material/icon'
import { SharedModule } from 'app/shared'
import { NgModule } from '@angular/core'
import { ProductFundraiserService } from './product-fundraiser.service'
import { LayoutModule } from "../layout/layout.module";
import {ProductFundraiserRoutingModule} from "./product-fundraiser-routing.module";

const providers: any[] = [
    ProductFundraiserService
];

@NgModule({
    declarations: [
      ProductFundraiserComponent
    ],
    providers: providers,
    exports: [
      ProductFundraiserComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        AlertModule.forRoot(),
        AuthFormModule,
        MatStepperModule,
        MatIconModule,
        SharedModule,
        LayoutModule,
        ProductFundraiserRoutingModule
    ]
})
export class ProductFundraiserModule {}
