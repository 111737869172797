import {Injectable, OnDestroy, Type} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import { CacheService } from '../../core/services/utility/cache.service';
import {HttpService, IEarnResponse, IRequestOptions} from "../../core";
import {Fundraiser, Team} from "../../shared/models";

@Injectable({
  providedIn: 'root'
})
export class ProductFundraiserService implements OnDestroy {
  private readonly FUNDRAISER_CACHE_KEY_PREFIX = 'getFundraiser';
  private readonly TEAM_CACHE_KEY_PREFIX = 'getTeam';

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private http: HttpService,
    private cache: CacheService) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRaisifyPlusProductFundraiser(fundraiserId: string, noCache = false): Observable<Fundraiser> {
    const req$ = this.http.get<IEarnResponse & { fundraiser: Fundraiser }>('/fundraiser/' + fundraiserId)
      .pipe(map(res => res.fundraiser));

    return this.cache.setIfNotExists(this.FUNDRAISER_CACHE_KEY_PREFIX + fundraiserId, req$, { ttl: 10 * 60 * 1000, noCache });
  }

  getRaisifyPlusTeam(teamId: string, noCache = false): Observable<Team> {
    const req$ = this.http.get<IEarnResponse & { team: Team }>('/team/' + teamId)
      .pipe(map(res => res.team));

    return this.cache.setIfNotExists(this.TEAM_CACHE_KEY_PREFIX + teamId, req$, { ttl: 10 * 60 * 1000, noCache });
  }

  getInvite(inviteId: string, noCache = false): Observable<any> {
    const req$ = this.http.get<IEarnResponse & { invite: any }>('/invite/' + inviteId)
      .pipe(map(res => res.invite));

    return this.cache.setIfNotExists(this.TEAM_CACHE_KEY_PREFIX + inviteId, req$, { ttl: 10 * 60 * 1000, noCache });
  }

  completePurchase(purchase: any){ // {type: string, inviteId: string, fundraiserId: string, donateOnly: boolean, donationAmount: number, creditCard: { number: string, expirationMonth: string, expirationYear: string}}) {
    const reqOptions = {
      body: purchase
    };
    return this.http.post<any>(`/membership/purchase`, reqOptions)
      .pipe(map(x => {
        return x
      }));
  }
}
