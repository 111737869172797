import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { HomeModule } from './modules/home';
import { LayoutModule } from './modules/layout/layout.module';
import { CreateMyTeamModule } from './modules/create-my-team/create-my-team.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { LogoutModule } from './modules/logout/logout.module';
import { AccountAuthModule } from './modules/account-auth/account-auth.module';
import { FormsModule } from '@angular/forms'
import { ProductFundraiserModule } from './modules/product-fundraiser/product-fundraiser.module'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TypeaheadModule.forRoot(),
    LoadingBarModule,
    LoadingBarRouterModule,
    CoreModule,
    AppRoutingModule,
    AccountAuthModule,
    CreateMyTeamModule,
    HomeModule,
    LayoutModule,
    LogoutModule,
    FormsModule,
    ProductFundraiserModule,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
