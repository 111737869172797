import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ILayoutData } from '../layout.service';
import { LayoutTheme } from '../models/layout-theme.enum';
import { LayoutMode } from '../models/layout-mode.enum';
import { WPImagesService } from '../../../core/services';

@Component({
  selector: 'app-funnel-card-layout',
  templateUrl: './funnel-card-layout.component.html',
  styleUrls: ['./funnel-card-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FunnelCardLayoutComponent {
  public readonly defaultOptions: ILayoutData = {
    theme: LayoutTheme.TRANSPARENT,
    mode: LayoutMode.FUNNEL,
    hideFooterDivider: true,
    noContentPadding: true
  };

  public bgImgStyle: SafeStyle;

  constructor(private images: WPImagesService,
              private sanitizer: DomSanitizer) {
    this.bgImgStyle = this.sanitizer.bypassSecurityTrustStyle(`url('${this.images.background}')`);
  }
}
