import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { Logger } from './logger.service';
import { StorageService } from './services/storage/storage.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { FundraiserService } from './services/fundraiser/fundraiser.service';
import { CommunityService } from './services/community/community.service';
import { WPImagesService } from './services/images/wp-images.service';
import { AppLogoComponent } from './appLogo/appLogo.component';
import { UrlService } from './services/url';
import { HttpService } from './http';
import { DefaultHeadersInterceptor } from './http/interceptors/default-headers.interceptor';
import { DialogService } from './services/dialog/dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { LegacyTeamService } from './services/legacy/legacy-team.service';
import { TeamService } from './services/team/team.service';
import { LegacyFundraiserService } from './services/legacy/legacy-fundraiser.service';
import { BankTransactionService } from './services/bank-transaction/bank-transaction.service';
import { TeamMemberService } from './services/team/team-member.service';
import { TitleService } from './services/title/title.service';
import { TransferTokenGuard } from './guards/transfer-token.guard';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { NavigationService } from './services/navigation/navigation.service';
import { RouterHistoryService } from './services/navigation/router-history.service';
import { CacheService } from './services/utility/cache.service';
import { AccountService } from './services/account/account.service';
import { BrowserDetectService } from './services/browser/browser-detect.service';
import { BrowserExtensionService } from './services/browser/browser-extension.service';
import { GoogleService } from './services/google/google.service';
import { FormService } from './services/form/form.service';
import { InvalidAccessTokenInterceptor } from './http/interceptors/invalid-access-token.interceptor';
import { GeolocationService } from './services/utility/geolocation.service';
import { AuthCookieService, CookieService } from './services/utility/cookie';
import { AccountMenuService } from './services';

const providers = [
  Logger,
  HttpService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DefaultHeadersInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InvalidAccessTokenInterceptor,
    multi: true
  },
  StorageService,
  AuthGuard,
  AuthService,
  BankTransactionService,
  UrlService,
  AnalyticsService,
  LegacyTeamService,
  TeamService,
  TeamMemberService,
  LegacyFundraiserService,
  FundraiserService,
  CommunityService,
  DialogService,
  WPImagesService,
  TitleService,
  TransferTokenGuard,
  UnsavedChangesGuard,
  NavigationService,
  RouterHistoryService,
  BrowserDetectService,
  BrowserExtensionService,
  CacheService,
  CookieService,
  AuthCookieService,
  AccountService,
  AccountMenuService,
  GoogleService,
  FormService,
  GeolocationService
];

/**
 * Collection of singletons.
 * @see https://angular.io/guide/styleguide#core-feature-module
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule
  ],
  exports: [
    AppLogoComponent,
    RouterModule,
    HttpClientModule
  ],
  declarations: [AppLogoComponent],
  providers: providers
})
export class CoreModule {
  /**
   * @see https://angular.io/guide/styleguide#prevent-re-import-of-the-core-module
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
