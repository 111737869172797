import { Injectable } from '@angular/core';
import { StorageKey, UserRole } from '../../../shared/models';
import { StorageService } from '../storage/storage.service';
import { CookieService } from '../utility/cookie/cookie.service';

interface AccountRoute {
  title: string;
  path: string;
}

/**
 * The routes are an array of arrays, with each inner-array being a new section in the menu.
 */
type AccountRoutes = AccountRoute[][];

class AccountMenuRoutes {
  /**
   * Routes as they would appear within the account menu in the header.
   * This is what is saved in the cookie.
   */
  header: AccountRoutes = [];

  /**
   * Routes as they appear in the sidebar on the account pages.
   * Typically account-only routes (i.e., no logout).
   */
  account: AccountRoutes = [];
}

@Injectable({
  providedIn: 'root'
})
export class AccountMenuService {
  public isAuthenticated = false;
  public displayName: string = 'Account';

  public routes: AccountMenuRoutes = new AccountMenuRoutes();

  private readonly cookie = {
    name: 'ht_account_menu',
    options: { domain: '.myraisify.com' }
  };

  constructor(private cookieService: CookieService,
              private storage: StorageService) {
    this.update();
  }

  public update(isAuthenticated?: boolean): void {
    if (isAuthenticated != null) {
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated) {
        this.clear();
        return;
      }
    }

    this.displayName = this.storage.get(StorageKey.DisplayName) || 'Account';
    const roles = this.storage.getUserRoles();

    this.routes.account = [
      [
        { title: 'Account', path: '/account/profile' },
        { title: 'My Fundraisers', path: '/account/fundraiser' },
        { title: 'My Raisify+ Membership Drives', path: '/account/fundraiser' },
      ]
    ];

    /**
     * Do NOT add anymore routes to the account routes after this point unless you do not want them included in
     * the header via {@link AccountMenuComponent}.
     */
    this.routes.header = [...this.routes.account];

    if (this.hasRole(UserRole.User, roles)) {
      this.routes.header.push([ { title: 'Admin Panel', path: '/admin' } ]);
    }

    this.routes.header.push([ { title: 'Logout', path: '/logout' } ]);

    this.updateCookie();
  }

  private clear(): void {
    this.displayName = null;
    this.routes = new AccountMenuRoutes();
    this.cookieService.delete(this.cookie.name, this.cookie.options);
  }

  private hasRole(role: UserRole, roles: UserRole[]): boolean {
    return roles.includes(role) || roles.includes(UserRole.Admin);
  }

  private updateCookie(): void {
    const cookieValue = { displayName: this.displayName, routes: (this.routes && this.routes.header) || [] };
    this.cookieService.set(this.cookie.name, JSON.stringify(cookieValue), this.cookie.options);
  }
}
