import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatStepper } from '@angular/material/stepper'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject, observable } from 'rxjs'
import { ProductFundraiserService } from './product-fundraiser.service'
import * as moment from 'moment'
import {Fundraiser} from "../../shared/models";
import { Logger } from '../../core';

@Component({
  selector: 'app-product-fundraiser',
  templateUrl: './product-fundraiser.component.html',
  styleUrls: ['./product-fundraiser.component.scss']
})
export class ProductFundraiserComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper

  public editable = true

  public stepIndex: number = 0

  public donateOnly: boolean = false
  public invite: any = null
  public inviteStr: string = null

  private destroy$ = new Subject<void>()

  public errorMessage: string = ''
  public ccErrorMessage: string = ''
  public expirationErrorMessage: string = ''
  public cvvErrorMessage: string = ''
  public donationErrorMessage: string = ''

  public productFundraiser: Fundraiser
  public fundraiserNotFound: boolean = false
  public inviteNotFound: boolean = false
  public membershipActive: boolean = false

  public creditCard = {
    number: '',
    cvv: '',
    expiration: {
      month: moment(new Date()).month(),
      year: moment(new Date()).year()
    }
  }

  public donationAmount: number = 0

  constructor(private router: Router,
    private route: ActivatedRoute,
    private service: ProductFundraiserService,
    private logger: Logger) {

  }

  ngOnInit() {
    let teamId = this.route.snapshot.queryParamMap.get('teamId');
    let inviteId = this.route.snapshot.queryParamMap.get('inviteId');
    this.donateOnly = this.route.snapshot.queryParamMap.get('donate') === '1';

    if (inviteId) {
      this.invite = inviteId;
      this.service.completePurchase({inviteId})
        .subscribe(
          invite => {

            if (invite.success) {
              this.invite = invite
              this.inviteStr = JSON.stringify(invite)
              this.membershipActive = true;
            } else {
              this.inviteStr = invite.message
            }
          },
          err => {
            this.inviteNotFound = true
          }
        );
    } else {
      this.service.getRaisifyPlusTeam(teamId)
        .subscribe(team => {
          let fundraiserId = team.fundraiserId;
          this.service.getRaisifyPlusProductFundraiser(fundraiserId)
            .subscribe(
              fundraiser => {
                this.productFundraiser = {
                  communityId: 'e9b7485d-fc35-3d43-b634-f1fb01018dcc', // Not used
                  communityName: 'XXX', // Not used
                  fundraiserId: 'xxxx', // Not used
                  status: 'xxxx',
                  fundsPayeeType: 'xxxx',
                  fundsTeamSplit: 1,
                  teamsAllowed: true,

                  id: 'e9b7485d-fc35-3d43-b634-f1fb01018dcc',
                  name: 'Raisify+',
                  description: 'A fundraiser earning funds for Waterville Area YMCA of Waterville Area YMCA, a Nonprofit Community.',
                  shortDescription: 'Raisify+ Membership',
                  endDate: Date.now(),
                  ourStory: "Help this organization do more! Donate to this fundraiser. We'll send them your donation along with a letter letting them know you're making a difference!",
                  price: 2.50,
                  earnAmount: 2.00,
                  imageUrl: 'https://placekitten.com/300/200',
                  ...fundraiser
                }
              },
              err => {
                this.fundraiserNotFound = true
              }
            );
        })
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  donateOnlyNext() {
    this.donateOnly = true
    this.next()
  }

  purchaseNext() {
    this.donateOnly = false
    this.next()
  }

  getEndDate() {
    return moment(this.productFundraiser.endDate).format('LL')
  }

  back() {
    this.stepper.selected.completed = true
    this.stepper.previous()

    this.stepIndex = this.stepper.selectedIndex
  }

  next() {
    this.errorMessage = ''
    this.stepper.selected.completed = true
    this.stepper.next()

    this.stepIndex = this.stepper.selectedIndex
  }

  done() {
    this.router.navigateByUrl('/account')
  }

  completePurchase() {
    this.errorMessage = ''
    this.editable = false
    this.service.completePurchase({
      type: 'raisifyPlus',
      inviteId: this.invite ? this.invite.id : null,
      fundraiserId: this.productFundraiser.id,
      donateOnly: this.donateOnly,
      donationAmount: this.donationAmount,
      creditCard: {
        number: this.creditCard.number,
        expirationMonth: this.creditCard.expiration.month.toString(),
        expirationYear: this.creditCard.expiration.year.toString(),
      }
    }).subscribe(x => {
      if (x.success == false) {
        this.errorMessage = x.errorMessage
        this.editable = true
      } else {
        this.next()
      }
    }, err => {
      this.errorMessage = 'An error occurred. Please try again.'
      this.editable = true
    })
  }

  validateExpiration() {
    const now = moment(new Date())
    return this.creditCard.expiration.year > now.year()
      || (this.creditCard.expiration.year === now.year() && this.creditCard.expiration.month >= now.month())
  }

  validateCcStep() {
    this.expirationErrorMessage = ''
    this.ccErrorMessage = ''
    this.cvvErrorMessage = ''
    this.donationErrorMessage = ''

    let success = true
    if (!this.validateExpiration()) {
      this.expirationErrorMessage =  'Expiration date must be in the future.'
      success = false
    }

    if (this.creditCard.number.length < 12) {
      this.ccErrorMessage = 'Credit Card invalid.'
      success = false
    }

    if (this.creditCard.cvv.length == 0) {
      this.cvvErrorMessage = 'CVV required.'
      success = false
    }

    if (this.donateOnly && this.donationAmount <= 0) {
      this.donationErrorMessage = 'Donation amount required.'
      success = false
    }

    if (success) {
      this.next()
    }
  }

  getMaskedNumber() {
    return `**** ${this.creditCard.number.slice(-4)}`
  }

  getExpiration() {
    return `${String(this.creditCard.expiration.month).padStart(2, '0')}/${this.creditCard.expiration.year}`
  }
}
