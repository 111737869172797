<div class="funnel-card-layout-container">
  <div class="background-radial-pattern" [style.background-image]="bgImgStyle"></div>
  <app-layout [defaultOptions]="defaultOptions">
    <div class="funnel-card-layout funnel-mode">
      <div class="content-container">
        <div class="container">
          <div class="row">
            <div id="layout-content-card-container" class="col-form">
              <div class="card">
                <div class="card-body">
                  <router-outlet></router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-mobile">
        <div class="wave-divider top-divider"></div>
      </div>

      <div class="wave-divider bottom-divider footer-divider"></div>
    </div>
  </app-layout>
</div>
