import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

/**
 * Get the environment-driven URL an image on the WordPress website.
 */
@Injectable()
export class WPImagesService {
  get logoHorizontalWhite(): string {
    return this.get('logo-horizontal-white.png');
  }

  get logo(): string {
    return this.get('logo-horizontal.png');
  }

  get logoStacked(): string {
    return this.get('logo-stacked.png');
  }

  get huterraBrandMark(): string {
    return this.get('logo-huterra-circle.png');
  }

  get huterraBrandMarkWhite(): string {
    return this.get('logo-huterra-circle-white.png');
  }

  get background(): string {
    return this.get('bullseye-gradient.svg');
  }

  get appleAppStoreBadge(): string {
    return this.get('apple-app-store-300x89.png');
  }

  get googleAppStoreBadge(): string {
    return this.get('google-play-300x89.png');
  }

  get(file: string) {
    return `${environment.huterra.website.url}wp-content/uploads/${file}`;
  }
}
