import { Component, OnDestroy, Input } from '@angular/core';
import { BrowserDetectService, BrowserName, AccountService, Logger, BrowserExtensionService } from '../../../../core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-start-shopping',
  templateUrl: './start-shopping.component.html',
  styleUrls: ['./start-shopping.component.scss']
})
export class StartShoppingComponent implements OnDestroy {
  @Input() mode: 'signup' | 'signin';

  public readonly BrowserName = BrowserName;

  public alert: { type: string, msg: string } = {
    type: 'success',
    msg: null
  };

  public isResendingEmail = false;

  constructor(public browserDetect: BrowserDetectService,
              public browserExt: BrowserExtensionService,
              private accountService: AccountService,
              private logger: Logger) {
  }

  ngOnDestroy() {
    const contentCardEle = document.getElementById('layout-content-card-container');
    contentCardEle.classList.remove('full-width');
  }

  public resendVerificationEmail(): void {
    this.isResendingEmail = true;
    this.accountService.resendVerificationEmail()
      .pipe(finalize(() => this.isResendingEmail = false))
      .subscribe(
        () => {
          this.alert = {
            type: 'success',
            msg: 'Verification email sent.'
          };
        },
        err => {
          this.logger.error(err);
          this.alert = {
            type: 'danger',
            msg: err
          };
        }
      );
  }

  /**
   * Hook called when a step is selected (AKA displayed to the user).
   * When using {@link MatStepper}, all steps are loaded immediately. This means their life cycle hooks are called after
   * the page loads, rather than when the step is selected.
   */
  public onStepSelect(): void {
    const contentCardEle = document.getElementById('layout-content-card-container');
    contentCardEle.classList.add('full-width');
  }
}
