// This file contains variables used to create other environment variables.
// These variables are the same for all environments.

const cdnUrl = 'https://dxbgcss8qcq19.cloudfront.net/';

export const envBase = {
  deepLinkUrl: 'myraisify://app.myraisify.com',
  supportEmail: 'customercare@myraisify.com',
  cdnUrl,
  mobileApp: {
    ios: {
      url: 'https://apps.apple.com/us/app/my-raisify/id6450326136',
      badge: `${cdnUrl}badge/apple_app_store.png`,
      icon: 'apple',
      alt: `Download on the App Store`
    },
    android: {
      url: 'https://play.google.com/store/apps/details?id=com.myraisify.mobile',
      badge: `${cdnUrl}badge/google_play.png`,
      icon: 'android',
      alt: `Get it on Google Play`
    }
  }
};
