import { GiveType } from './give-type.enum';
import { Business } from './business.model';
import { GivePercentDto } from './give-percent-dto.model';

class GivePercentIcon {
  constructor(public type: 'material' | 'fa', public name: string) {}
}

export interface IGivePercent extends GivePercentDto {
  readonly label: string;
  readonly icon: GivePercentIcon;
  readonly inAppOnly: boolean;
}

export class GiftCardGivePercent implements IGivePercent {
  public readonly label: string = 'Buy Gift Card';
  public readonly icon: GivePercentIcon = new GivePercentIcon('material', 'payment');
  public readonly inAppOnly = true;

  constructor(public percent: number,
              public isUpTo: boolean,
              public giftCardBusinessId: string,
              public type: GiveType.EGiftCard | GiveType.PhysicalGiftCard = GiveType.EGiftCard) {
  }
}

export class OnlineGivePercent implements IGivePercent {
  public readonly type = GiveType.ShopOnline;
  public readonly label: string = 'Shop Online';
  public readonly icon: GivePercentIcon = new GivePercentIcon('material', 'shopping_basket');
  public readonly inAppOnly = false;

  constructor(public percent: number,
              public isUpTo: boolean,
              public onlineStoreUrl: string) {
  }
}

export class LocalGivePercent implements IGivePercent {
  public readonly type = GiveType.ShopLocal;
  public readonly label: string = 'Scan to Give';
  public readonly icon: GivePercentIcon = new GivePercentIcon('fa', 'receipt');
  public readonly inAppOnly = true;

  constructor(public percent: number, public isUpTo: boolean) {}
}

