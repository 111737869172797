<div class="section">
  <h4>You're almost ready to start earning!</h4>
</div>

<div class="section" *ngIf="mode === 'signup'">
  <h5>Verify Your Email Address</h5>

  <p class="color-light">
    We've sent you a link to verify your email address. Verified accounts have access to all features in Raisify, including buying gift cards.
  </p>

  <alert [type]="alert.type" *ngIf="alert?.msg" dismissible="true">
    {{alert.msg}}
  </alert>

  <button class="btn btn-outline-primary btn-padded" [disabled]="isResendingEmail"
          (click)="resendVerificationEmail()">
    <i class="fas fa-spinner fa-spin" *ngIf="isResendingEmail"></i>
    <span *ngIf="!isResendingEmail">Resend Email</span>
  </button>
</div>

<div class="final-steps-content section">
  <section class="download-app-section">
    <h5>Download the App</h5>

    <div class="color-light">
      <p>
        Download the Raisify app and start earning while shopping at your favorite stores.
      </p>

      <div class="app-badges">
        <app-badge type="ios"></app-badge>
        <app-badge type="android"></app-badge>
      </div>

      <p>
        Use the app to share your fundraiser to get others to join in and raise more for your cause.
      </p>
    </div>
  </section>

<!--  Disabled until browser extensions are rebranded. -->
<!--  <section class="download-browser-extension-section" *ngIf="!browserDetect.isMobile">-->
<!--    <h5>Download the Browser Extension</h5>-->

<!--    <p class="color-light">-->
<!--      Our RaiseUp browser assistant automatically asks if you want to earn rewards every time you shop online at any of our participating name brand stores. Just shop online like you always do, click the prompt, and let us take care of the rest.-->
<!--    </p>-->

<!--    <div class="browser-extension-video-container">-->
<!--&lt;!&ndash;      TODO: update to raisify video instead of huterra&ndash;&gt;-->
<!--      <iframe width="360" height="202" src="https://www.youtube.com/embed/k71KMm7mg_A?rel=0" frameborder="0"-->
<!--              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>-->
<!--      </iframe>-->
<!--    </div>-->

<!--    <div class="browser-extension-downloads-container">-->
<!--      <div class="promote-primary-browser" *ngIf="browserExt?.targets.primary; else noPrimaryBrowserTargetTemplate">-->
<!--        <ng-container *ngTemplateOutlet="browserTargetBtn; context: { $implicit: browserExt?.targets.primary }">-->
<!--        </ng-container>-->

<!--        <a class="browser-target-secondary" [href]="browserExt?.targets.secondary?.url">-->
<!--          <span>Or download for {{browserExt?.targets.secondary?.browser | titlecase}}</span>-->
<!--          <img [src]="browserExt?.targets.secondary?.logo" />-->
<!--        </a>-->
<!--      </div>-->

<!--      <ng-template #noPrimaryBrowserTargetTemplate>-->
<!--        <div class="no-promote-primary-browser">-->
<!--          <ng-container *ngTemplateOutlet="browserTargetBtn; context: { $implicit: browserExt?.extensions.chrome }">-->
<!--          </ng-container>-->
<!--          <ng-container *ngTemplateOutlet="browserTargetBtn; context: { $implicit: browserExt?.extensions.firefox }">-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </div>-->
<!--  </section>-->
</div>

<div class="section">
  <a class="btn btn-primary shop-online-button btn-padded" routerLink="/account">Finish</a>
</div>

<ng-template #browserTargetBtn let-extension>
  <a class="btn btn-outline-primary" [href]="extension?.url"
     [target]="extension?.target || '_self'">
    <span>Download for {{extension?.browser | titlecase}}</span>
    <img [src]="extension?.logo" />
  </a>
</ng-template>
