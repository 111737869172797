import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BankTransaction} from '../../../shared/models';
import {withoutNullProperties} from '../../../shared/util';
import {map} from 'rxjs/operators';
import { BankTransactionType } from '../../../shared/models/bank-transaction.model';
import { HttpService, IEarnResponse } from '../../http';

@Injectable()
export class BankTransactionService {

    constructor(private http: HttpService) { }

    /**
     * Upload a file to be validated and processed by the batch processor.
     */
    public upload(file: File): Observable<any> {
      return this.http.postFile('/admin/banktransaction/upload', 'bankTransactions', file);
    }

    public transactionsByMember(memberId: string): Observable<BankTransaction[]> {
      const options = {
        params: withoutNullProperties({
          memberId: memberId
        })
      };

      return this.http.get<IEarnResponse & { bankTransactions: BankTransaction[] }>(`/admin/rewards/transaction`, options)
        .pipe(map(res => {
          return res.bankTransactions;
        }));
    }

    public addBankAdjustment(npoType: string, npoId: string, transactionDateTime: Date, transactionType: BankTransactionType,
                              amount: number, memo: string, sourceReferenceData: object): Observable<any> {
      const options = {
        params: withoutNullProperties({
          npoType: npoType,
          npoId: npoId,
          transactionDateTime: transactionDateTime.toISOString(),
          transactionType: transactionType,
          amount: amount,
          sourceReferenceData: JSON.stringify(sourceReferenceData),
          memo: memo
        })
      };

      return this.http.post('/admin/rewards/adjustment', options);
    }
}
