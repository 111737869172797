<div class="footer-container" [ngClass]="{ 'no-divider': layout.hideFooterDivider }">
  <div id="footer-top">
    <div class="container footer-top-row">
      <div class="row">
        <div id="footer-col-brand" class="footer-col col-md-3">
          <div class="footer-logo">
            <a [href]="url.website">
              <img [src]="images.logoStacked" alt="Raisify">
            </a>
          </div>
          <div class="footer-social">
            <a href="https://www.facebook.com/myraisify" target="_blank" aria-label="Facebook">
              <span class="fab fa-facebook-f fa-fw"></span>
            </a>
            <a href="https://youtube.com/@MyRaisify" target="_blank" aria-label="Youtube">
              <span class="fab fa-youtube fa-fw"></span>
            </a>
          </div>
        </div>

        <div id="footer-col-main-menu" class="footer-col col-md-7">
          <div class="footer-menu-col" style="display:none">
            <div class="menu-section">
              <h4>Download</h4>
              <div class="submenu-section">
                <h6>Mobile App</h6>
                <ul>
                  <ng-container *ngTemplateOutlet="mobileAppItem; context: { $implicit: 'android' }">
                  </ng-container>
                  <ng-container *ngTemplateOutlet="mobileAppItem; context: { $implicit: 'ios' }">
                  </ng-container>
                </ul>
              </div>

              <div class="submenu-section">
                <h6>Browser Extension</h6>
                <ul>
                  <ng-container *ngIf="browserExt?.targets.primary; else noPrimaryBrowserTarget">
                    <ng-container *ngTemplateOutlet="browserTargetItem; context: { $implicit: browserExt?.targets.primary }">
                    </ng-container>
                  </ng-container>

                  <ng-template #noPrimaryBrowserTarget>
                    <ng-container *ngTemplateOutlet="browserTargetItem; context: { $implicit: browserExt?.extensions.chrome }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="browserTargetItem; context: { $implicit: browserExt?.extensions.firefox }">
                    </ng-container>
                  </ng-template>
                </ul>
              </div>
            </div>
          </div>

          <div class="footer-menu-col">
            <div class="menu-section">
              <h4>Fundraisers</h4>
              <ul>
                <li><a [href]="url.website + 'info/fundraisers/wizard'">Create a Fundraiser</a></li>
                <li><a [href]="url.website + 'resources/'" target="_blank">Admin Resources</a></li>
                <li><a [href]="url.website + 'resources/'" target="_blank">Team Owner Resources</a></li>
              </ul>
            </div>

            <div class="menu-section">
              <h4>Businesses</h4>
              <ul>
                <li><a [href]="url.website + 'info/businesses'">Business Sign Up</a></li>
              </ul>
            </div>
          </div>

          <div class="footer-menu-col">
            <div class="menu-section">
              <h4>Members</h4>
              <ul>
                <li>
                  <a *ngIf="isAuthenticated" routerLink="/account">Account</a>
                  <a *ngIf="!isAuthenticated" routerLink="/signup">Sign Up</a>
                </li>
                <li><a routerLink="/business">Explore Businesses</a></li>
                <li><a routerLink="/business/online">Shop Online</a></li>
              </ul>
            </div>
          </div>

          <div class="footer-menu-col">&nbsp;</div>

          <div class="footer-menu-col huterra-menu-mobile">
            <ng-container *ngTemplateOutlet="huterraMenu"></ng-container>
          </div>
        </div>

        <div id="footer-col-huterra-menu-desktop" class="footer-col huterra-menu-desktop col-md-2">
          <ng-container *ngTemplateOutlet="huterraMenu"></ng-container>
        </div>
      </div>

      <div class="row">
        <div class="footer-col-foundation-spacer footer-col col-md-3">
          <!-- Empty div only used to line up the foundation text. -->
        </div>

        <div id="footer-col-foundation" class="footer-col col-md-7">
          Donations and rewards made to nonprofit organizations are processed by the RaiseUp Foundation.
          The RaiseUp Foundation is a registered <span style="white-space: nowrap;">501(c)(3)</span> dedicated to
          helping nonprofit organizations further their initiatives by building stronger communities.
        </div>
      </div>
    </div>
  </div>

  <div id="footer-bottom" class="text-center">
    &copy; {{year}} Raisify
    <ul>
      <li><a [href]="url.website + 'terms'">Terms</a></li>
      <li><a [href]="url.website + 'privacy'">Privacy</a></li>
    </ul>
  </div>
</div>

<ng-template #huterraMenu>
  <div class="menu-section">
    <h4>Raisify</h4>
    <ul>
      <li><a [href]="url.website + 'about'">About Us</a></li>
      <li><a [href]="url.faq" target="_blank">FAQ</a></li>
      <li><a [href]="url.website + 'resources'">Resources</a></li>
      <li><a [href]="url.website + 'contact'">Contact Us</a></li>
    </ul>
  </div>
</ng-template>

<ng-template #browserTargetItem let-extension>
  <li *ngIf="extension">
    <a [href]="extension.url" [target]="extension.target || '_self'">
      <i [class]="'fab fa-fw fa-' + extension.icon"></i>
      Download for {{extension.browser | titlecase}}
    </a>
  </li>
</ng-template>

<ng-template #mobileAppItem let-type>
  <li>
    <a [href]="mobileApp[type].url" target="_blank" *ngIf="mobileApp[type]">
      <i [class]="'fab fa-fw fa-' + mobileApp[type].icon"></i>
      Download for {{this.type === 'android' ? 'Android' : 'iOS'}}
    </a>
  </li>
</ng-template>
